@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.dashboard {
  padding: 0px 0px;

  &__card {
    height: 200px;
    border-radius: 18px;
    background-image: url("../../../assets/images/CardMask.png");
    background: url("../../../assets/images/CardMask.png"),
      linear-gradient(132.79deg, #7647ee 13.52%, #1d3178 78.88%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @include respond-to("mobile") {
      height: 185px;
    }
    .btn {
      height: 40px;
      border-radius: 20px;
      border: none;
      padding: 0 18px;
      font-size: 14px;
    }
  }

  &__tab {
    display: flex;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 72px;
    z-index: 1;
    background: $white-color;
    .tabItem {
      flex: 1;
      padding: 10px;
      border-bottom: 2px solid transparent;
      text-align: center;
      color: $light-gray-color;
      background: $white-color;

      &.active {
        border-color: $purple-color;
        color: $purple-color;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__content {
    padding: 20px 0;
  }
}
