@import "../../assets/styles/base/variables";

.appLayout {
  &__header {
    padding: 20px 5%;
    border: 1px solid #ddd;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white-color;
    z-index: 1;

    &__logo {
      width: 90px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      cursor: pointer;
      max-height: 40px;
    }

    &__points {
      min-width: 66px;
      height: 30px;
      border-radius: 20px;
      color: $white-color;
      background: $dark-purple-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 15px 1px 15px;
      }
    }
  }

  &__content {
    padding: 25px 5%;
  }
}
