@import "../../../assets/styles/base/variables";

.checkbox {
  position: relative;

  input[type="checkbox"] {
    display: none;

    & + label {
      cursor: pointer;

      &::after {
        content: "";
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        top: 2px;
        position: absolute;
        border-radius: 0.25em;
        border: 2px solid #97979766;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
      }
    }

    &:checked + label::after {
      content: "\2713";
      color: $white-color;
      background: #19ae57;
      border-color: #19ae57;
    }
  }
}
