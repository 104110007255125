.loginLayout {
  padding: 50px 5%;

  &__logo {
    width: 100px;
  }

  &__content {
    padding-top: 80px;
  }
}
