@import "../../../assets/styles/base/variables";

.password-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input-section {
    height: 56px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #f3f1fc;

    &:hover {
      border-color: $purple-color;
    }

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    input {
      height: 100%;
      border: none;
      outline: none;
      flex: 1;
      padding: 0 1em;
      background: #f3f1fc;
    }

    div {
      flex-grow: 0.08;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 0;
      cursor: pointer;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 3px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
