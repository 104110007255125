@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.giftCards {
  .cardsContainer {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, minmax(calc(50% - 18px), 1fr));
    .cardItem {
      cursor: pointer;
      margin-bottom: 8px;
      max-width: 100%;
      &:hover {
        text-decoration: none;
      }
      &__card {
        height: 120px;
        background: $light-bg;
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        @include respond-to("mobile") {
          height: 100px;
        }
        .brand {
          height: 50px;
          width: 50px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          background: $white-color;
          padding: 5px;
          overflow: hidden;
          @include respond-to("mobile") {
            min-height: 40px;
            min-width: 40px;
          }
          img {
            max-width: 100%;
          }
        }
      }
      .points {
        min-width: 50px;
        height: 24px;
        border-radius: 20px;
        color: $white-color;
        background: $dark-purple-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 10px 1px 10px;
        }
      }
      .amount {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .viewMore {
    position: sticky;
    bottom: 0px;
    padding: 20px 0;
    background: $white-color;
  }
}
