@import "../../../assets/styles/base/variables";

.boxed-radio {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 72px;
  padding: 24px 16px 24px 24px;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(151, 151, 151, 0.8);

  &.active {
    border-color: $purple-color;
  }

  &.disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.05);
  }
}
