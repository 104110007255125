* {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
}

//set body properties
body {
  margin: auto;
  padding: 0px;
  max-width: 1600px;
}

ul {
  list-style: none;
}

button {
  outline: none;
  cursor: pointer;
}

hr {
  margin: 8px 0;
}

label {
  margin-bottom: 0;
}

span {
  line-height: 1;
}
