@import "../../../assets/styles/base/variables";
.giftCardSuccess {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  height: 70vh;
  .giftCardCode {
    border: 1.5px dashed $purple-color;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-size: 20px;
    color: $purple-color;
    margin: 30px 0;
    background: #f2edfe;
  }
}
