h1 {
  font-size: 48px; // adjust font size to the ones on the design
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 35px;
  }
}

h3 {
  font-size: 32px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 29px;
  }
}

h4 {
  font-size: 24px;
  font-weight: bold;
  color: $dark-color;

  @include respond-to("tablet") {
    font-size: 21px;
  }
}

h5 {
  font-size: 18px;
  font-weight: bold;
  color: $dark-color;
}

h6 {
  font-size: 16px;
  font-weight: bold;
  color: $dark-color;
}

label {
  color: $dark-color;
  font-size: 14px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  color: $light-gray-color;
  font-size: 16px;

  // @include respond-to("tablet") {
  //   font-size: 14px;
  // }
}

a {
  font-size: 16px;
  font-weight: normal;
  color: $gray-color;
  text-decoration: none;
}

.default-font-size {
  font-size: 16px;
}

.big-bold-text-size {
  font-size: 55px;
}

.big-text-size {
  font-size: 20px;

  @include respond-to("tablet") {
    font-size: 18px;
  }
}

.small-text-size {
  font-size: 14px;
}

.smallest-text-size {
  font-size: 12px;

  @include respond-to("tablet") {
    font-size: 12px;
  }
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-semibold {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

input::placeholder {
  font-size: 16px;
}
.text-underline {
  text-decoration: underline;
}
