@import "../../../assets/styles/base/variables";

.settings {
  display: flex;
  flex-direction: column;
  min-height: 80vh;

  .links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 25px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .avatar {
        min-width: 50px;
        min-height: 50px;
        background: $light-bg;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        color: $purple-color;
        text-transform: capitalize;
      }
    }
  }
}
