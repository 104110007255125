@import "../../../../../assets/styles/base/variables";

.earnings {
  &__items {
    display: flex;
    flex-direction: column;

    .earningItem {
      padding: 15px 0;
      border-bottom: 1px solid #ddd;
      .points {
        min-width: 60px;
        height: 24px;
        border-radius: 20px;
        color: $white-color;
        background: $sucess-color;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    }
  }
}
