@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.api-error-handler {
  min-height: 70px;
  min-width: 300px;
  max-width: 500px;
  padding: 20px;
  background: #fd561f;
  border-radius: 8px;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 2000;
  @include respond-to("tablet") {
    width: calc(100% - 5%);
  }
  &__close {
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
  }
}
