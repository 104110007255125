// Edit this section an set color variable with occurdance with the design
$main--bg: #e5e5e5;
$purple-color: #7647ee;
$dark-purple-color: #1e016f;
$white-color: #ffffff;
$dark-color: #15112d;
$gray-color: #9091b3;
$light-gray-color: #91919f;
$danger-color: #dc3545;
$light-bg: #e4dafc;
$sucess-color: #19ae57;
$light-danger-bg: #ffddd2;
$ligth-sucess-bg: #e8f7ee;

.white {
  background: $white-color !important;
  &--text {
    color: $white-color !important;
  }
}
.purple {
  background: $purple-color;
  &--text {
    color: $purple-color;
  }
}
.gray {
  background: $light-gray-color;
  &--text {
    color: $light-gray-color;
  }
}
.dark {
  background: $dark-color;
  &--text {
    color: $dark-color;
  }
}
.error {
  background: $danger-color;
  &--text {
    color: $danger-color;
  }
}

.success {
  background: $sucess-color;
  &--text {
    color: $sucess-color;
  }
}
