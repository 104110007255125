@import "../../assets/styles/base/mixins";

.mainLayout {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  min-height: 100vh;
  width: 600px;
  margin: auto;
  @include respond-to("tablet") {
    width: auto;
    min-height: auto;
    border: none;
  }
}
.request-loader {
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 600px;
  @include respond-to("tablet") {
    width: 100%;
  }

  &__bar {
    width: 100%;
    height: 3px;
    background: transparent;
    transition: 0.5s;
    &__progress {
      width: 0;
      height: 100%;
      background: #f7ad3c;
    }
  }
  &__spinner {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    padding-right: 17px;
    @include respond-to("mobile") {
      padding-right: 5px;
    }
  }
}
