@import "../../../../../assets/styles/base/variables";

.purchases {
  &__items {
    display: flex;
    flex-direction: column;

    .purchaseItem {
      padding: 20px 0 15px 0;
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .rowItem {
        display: flex;
        justify-content: space-between;
      }
      .points,
      .status {
        min-width: 60px;
        height: 24px;
        border-radius: 20px;
        color: $white-color;
        background: $danger-color;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }

      .status {
        color: $danger-color;
        background: $light-danger-bg;
        &.redeemed {
          background: $ligth-sucess-bg;
          color: $sucess-color;
        }
      }

      .codeSection {
        border: 1.5px dashed $purple-color;
        border-radius: 8px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        text-transform: capitalize;

        .btn {
          height: 32px;
          padding: 0 15px;
          font-size: 12px;
        }
      }
    }
  }
}
