@import "../../../../assets/styles/base/variables";

.history {
  &__tab {
    border: 1px solid $purple-color;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    position: sticky;
    top: 116px;
    background: $white-color;
    z-index: 1;

    .tabItem {
      padding: 12px 15px;
      flex: 1;
      text-align: center;
      color: $light-gray-color;
      border-radius: 8px;
      background: $white-color;
      &.active {
        color: $white-color;
        background: $purple-color;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__content {
    padding: 10px 0;
  }
}
