.otp-input__field {
  width: 100%;

  div {
    justify-content: center;
  }

  input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding-top: 5px;
    font-size: 1.5rem;
    border: 1px solid #7647ee;
  }
}
