@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.giftCardDetails {
  min-height: 60vh;
  .brandCard {
    height: 90px;
    background-color: #f3f1fc;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include respond-to("mobile") {
      height: 80px;
      padding: 15px;
    }

    .giftCardimage {
      width: 60px;
      height: 60px;
      margin-right: 15px;
      @include respond-to("mobile") {
        width: 50px;
        height: 50px;
      }
    }
  }
  .points {
    min-width: 50px;
    height: 24px;
    border-radius: 20px;
    color: $white-color;
    background: $dark-purple-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px 1px 10px;
    }
  }
  .descriptionCard {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;

    .gift {
      min-width: 36px;
      min-height: 36px;
      max-width: 36px;
      max-height: 36px;
      border-radius: 8px;
      padding: 10px;
      background: $purple-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }
  }
  .buyGift {
    position: sticky;
    bottom: 0px;
    padding: 20px 0;
    background: $white-color;
    margin-top: 30px;
  }
}
